.tabsNavbar {
  border-bottom: 2px #f2f2f2 solid;
  top: 110px;
}
.tabsNavbar button::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -45%;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.tabsNavbar button:hover {
  color: #2c659d !important;
}
.tabsNavbar button:hover:after {
  width: 100%;
  background: #2c659d;
}
.tabsNavbar button.active {
  color: #2c659d !important;
}
.tabsNavbar button.active::after {
  width: 100%;
  background: #2c659d;
}
