.signalerButton {
  height: 40px;
  border: 2px #f3cd4b solid;
}
.signalerButton:hover {
  background-color: white !important;
  color: #f3cd4b !important;
}
.signalerButton.circularBtn {
  width: 33px;
  height: 33px;
}
.signalerButton.circularBtn .bi {
  -webkit-text-stroke: 0px;
  width: 18px;
  height: 35px;
}
