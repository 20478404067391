.layoutApp {
  position: relative;
  left: 130px;
  width: calc(100vw - 150px);
  max-width: 2120px;
  min-height: calc(100vh - 110px);
  margin-bottom: 50px;
  padding-right: 2rem;
}

/********************************* responsive **********************************/
@media screen and (max-width: 992px) {
  .layoutApp {
    left: 0px;
    width: 100%;
    padding: 0px 0.5rem;
    margin-bottom: 100px;
  }
}
