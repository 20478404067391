.loginView {
  height: 100vh !important;
  background-image: url("../Images/Images/connexion_taboni.png");
  background-size: cover;
}
.loginForm {
  padding-top: 3.5% !important;
  min-height: 365px;
  width: 675px;
}
.loginView .logoContainer {
  width: 130px;
  height: 130px;
  top: -65px;
}
.loginView .loginBtn i {
  -webkit-text-stroke: 1px;
}
.loginView a:hover {
  text-decoration: underline !important;
}
.loginView #polygone1 {
  position: absolute;
  z-index: 1;
  width: 123px;
  height: 130px;
  left: -43px;
  top: 0px;
}
.loginView #polygone2 {
  position: absolute;
  z-index: 0;
  width: 123px;
  height: 170px;
  left: -40px;
  top: -30px;
}
.loginView #polygone3 {
  position: absolute;
  z-index: 0;
  width: 93px;
  height: 130px;
  right: -30px;
  bottom: 20px;
}
.loginView #polygone4 {
  position: absolute;
  z-index: 1;
  width: 75px;
  height: 130px;
  right: -45px;
  bottom: 60px;
}
.loginView .passwordEye {
  position: absolute;
  right: 10px;
  top: 10px;
}
.loginView .passwordEye:hover {
  color: #2c659d;
}
@media screen and (max-width: 960px) {
  .loginForm {
    min-height: 450px;
  }
}
