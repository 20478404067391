.p-accordion .p-accordion-header-link {
  background-color: white !important;
  justify-content: space-between;
}
.p-accordion-header-link:hover {
  background-color: #2c659d !important;
}
.p-accordion-tab-active .p-accordion-header-link {
  display: flex;
  background-color: #2c659d !important;
  color: white;
  padding: 1.25rem;
}
.p-accordion .p-accordion-header .bi {
  font-size: 22px;
  color: #2c2c2c;
  order: 2;
}
.p-accordion-header-link:hover .bi,
.p-accordion-header-link:hover p,
.p-accordion-tab-active .p-accordion-header-link .bi,
.p-accordion-tab-active .p-accordion-header-link p {
  color: white !important;
}
.p-accordion .p-accordion-toggle-icon {
  margin-right: 0px !important;
}
.p-accordion .p-accordion-tab {
  margin-bottom: 3%;
}
