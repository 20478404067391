.InformationCard {
  padding: 2rem;
  padding-bottom: 0px;
  position: relative;
  min-width: 250px;
  box-shadow: 0px 2px 15px #00000014;
  min-height: 298px;
}
.informarion-card-polygone-container {
  top: -50px;
  left: -16px;
  width: 105px;
  height: 110px;
}
