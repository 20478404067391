.signalementFormContent > * {
  margin: 1% 0px;
}
.signalementFormContent input[type="file"] {
  display: none;
}
.signalementFormContent .custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  height: 48px;
}
