.navigateProfileCard {
  box-shadow: 0px 2px 15px #00000014;
}
.profileCardPolygoneAsset {
  top: -5%;
}
.profileCardPersonAsset {
  bottom: 0;
  right: 0;
}
