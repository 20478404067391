.notificationPanel .iconContainer {
  width: 40px;
  height: 40px;
}
.notificationPanel .iconContainer:hover {
  background-color: rgb(93, 165, 228);
}
.notificationPanel .notificationWrapper {
  position: absolute;
  top: 55px;
  left: -38px;
}
.notificationPanel.upward .notificationWrapper {
  position: absolute;
  left: -160px;
}
