.mobileNavbar {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100vw - 20px);
  height: 82px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
}
.mobileNavbar .signalerButton.circularBtn {
  width: 79px;
  height: 79px;
  margin-top: -50px;
  background-color: #f3cd4b !important;
  color: white !important;
}
.mobileNavbar .signalerButton.circularBtn .bi {
  -webkit-text-stroke: 0px;
  font-size: 60px;
  width: 60px;
  height: 85px;
}
@media screen and (max-width: 350px) {
  .mobileNavbar .linkTitle {
    display: none;
  }
}
