.profileImageContainer {
  height: 146px;
  width: 146px;
}
.profileImageContainer .iconContainer {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 115px;
  bottom: 10px;
}
.profileForm .blueOutlined:hover {
  border: 2px #2c659d solid;
}
