.cardWrapper {
  min-width: 200px;
  min-height: 298px;
  box-shadow: 0px 2px 15px #00000014;
}
.cardWrapper-plolygoneContainer {
  top: 10px;
  left: 2;
  width: 150px;
  height: 151px;
  transform: rotate(94deg);
}
.contactImg {
  width: 65px;
  height: 65px;
  margin-right: 1rem;
  border-radius: 50%;
}
.articleCardCommon .separator {
  margin-left: -3.75%;
}
