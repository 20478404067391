.customButton {
  display: block;
  height: 49px;
}
.linkContainer {
  display: block;
  width: fit-content;
}
.buttonSpinner .p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    buttonSpinner-color 6s ease-in-out infinite;
}
@keyframes buttonSpinner-color {
  100%,
  0% {
    stroke: white;
  }
}

/************************************** variants ***************************************/
.blue {
  color: white;
  border: 2px white solid;
  background-color: #2c659d;
}
.blue:hover {
  color: #2c659d;
  border: 2px #2c659d solid;
  background-color: white;
}
.blueOutlined {
  color: #2c659d;
  border: 2px #2c659d solid;
  background-color: white;
}
.blueOutlined:hover {
  color: white;
  border: 2px white solid;
  background-color: #2c659d;
}
.yellow {
  color: #102a44;
  border: 2px #f3cd4b solid;
  background-color: #f3cd4b;
}
.yellow:hover {
  color: #f3cd4b;
  border: 2px #102a44 solid;
  background-color: #102a44;
}
.disabled {
  cursor: not-allowed !important;
  color: #f2f2f2;
  border: 2px #e0e0e0 solid !important;
  background-color: #e0e0e0;
}
.transparentHover:hover {
  background-color: transparent !important;
}

/****************************** borders  *************************************/
.blueBorder {
  border-color: #2c659d;
}
.whiteBorder {
  border-color: white;
}
