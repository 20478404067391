.zIndex-0 {
  z-index: 0;
}
.zIndex-1 {
  z-index: 1;
}
.zIndex-2 {
  z-index: 2;
}
.zIndex-3 {
  z-index: 3;
}
.zIndex-4 {
  z-index: 4;
}
.zIndex-5 {
  z-index: 5;
}
.zIndex-9999 {
  z-index: 9999;
}
.zIndex-100 {
  z-index: 100;
}

/* Negattive z-index */

.zIndex-n-1 {
  z-index: -1;
}
