.linksPopup {
  right: -20px;
  top: 30px;
  min-width: 100px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.6rem 1rem;
}
.linkPopup {
  padding-bottom: 2px;
}
.linkPopup:hover {
  color: #2c659d !important;
}
