.signalementsMain .searchContainer button.blue {
  border-color: #2c659d;
  font-weight: 600;
}
.searchContainer input::placeholder {
  font-size: 13px;
  color: #222222;
}
.contactSection .tableRow {
  padding-right: 2.5rem;
}
.contactSection .searchButton {
  min-width: 160px;
}
@media screen and (max-width: 960px) {
  .contactSection .searchButton {
    min-width: 30px;
  }
}
