.viewHeader {
  position: sticky;
  top: 85px;
  z-index: 97;
  background-color: white;
  padding-top: 20px;
  width: 75%;
  margin-bottom: 10px;
}
@media screen and (max-width: 960px) {
  .viewHeader {
    width: 100%;
    top: 80px;
  }
}
