.folderCard {
  border: 1px #f2f2f2 solid;
  width: 222px;
  min-width: 222px;
  height: 81px;
}
.folderCard .iconContainer {
  border-radius: 50%;
  width: 38px;
  height: 38px;
}
.folderCard .iconContainer svg {
  width: 22px;
  height: 22px;
}

.folderCard .plusIconContainer {
  transform: rotate(90deg);
  width: 10px;
}
/* on hover */
.folderCard:hover {
  border: 1px #2c659d solid;
  background-color: #2c659d;
}
.folderCard:hover .iconContainer {
  background-color: #f3cd4b !important;
}
.folderCard:hover .iconContainer svg,
.folderCard:hover circle {
  fill: white;
}
.folderCard:hover p {
  color: white !important;
}
@media screen and (max-width: 992px) {
  .folderCard {
    height: 125px;
  }
  .folderCard .plusIconContainer {
    position: absolute;
    right: 15px;
    top: 18px;
    transform: rotate(0deg);
    width: auto;
  }
}
