.signalementsSection .p-accordion-tab {
  margin-top: -1px;
  margin-bottom: 0px;
}
.signalementsSection .p-accordion-header-link {
  border-radius: 0px !important;
  border-color: #f2f2f2 !important;
  min-height: 124px;
}
.signalementsSection .p-accordion-header-link:hover svg path,
.signalementsSection .p-accordion-tab-active .p-accordion-header-link svg path {
  fill: #f3cd4b;
}
.signalementsSection .p-accordion-header-link:has(.done) {
  background-color: #d1f5f0 !important;
}
.signalementsSection
  .p-accordion-tab-active
  .p-accordion-header-link:has(.done),
.signalementsSection .p-accordion-header-link:has(.done):hover {
  background-color: #2c659d !important;
}
.signalementsSection .p-accordion-header-link .bi {
  width: 10%;
  text-align: center;
}
.signalementsSection .p-accordion-content {
  border-radius: 0px;
  border-color: #f2f2f2;
}
.signalementsSection .p-accordion-tab:last-child .p-accordion-header-link {
  border-radius: 0px 0px 6px 6px !important;
}
.signalementsSection .signalementImg:hover {
  outline: 2px rgb(93, 165, 228) solid;
}
#ReactSimpleImageViewer {
  z-index: 101;
}
@media screen and (max-width: 992px) {
  .signalementsSection .p-accordion-tab-active .p-accordion-header-link {
    border-radius: 6px !important;
  }
  .signalementsSection .p-accordion-header-link .bi {
    align-self: start;
  }
  .signalementsSection .p-accordion-content {
    padding: 1rem;
  }
}
