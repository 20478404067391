.customInputContainer .customInput {
  height: 45px;
}
.customInputContainer .customInput:focus-visible {
  outline: none;
  border: 1px #2c659d solid !important;
}
.customInputContainer .customInput:hover {
  border: 1px #2c659d solid !important;
}
.customInputContainer .customInput::placeholder {
  font-size: 16px;
  color: #222222;
}
.customInputContainer svg {
  position: absolute;
  top: 25%;
  left: 2.5%;
}
