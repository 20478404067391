.budgetSection .exerciceComptable {
  margin-bottom: 8%;
}
.budgetSection .p-dropdown {
  height: 39px;
}
.budgetSection .p-dropdown-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333333;
}
.budgetSection .progressCircleContainer {
  width: 92px;
  height: 92px;
}
