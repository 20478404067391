.listHeader {
  border-bottom: 1px #fafafa solid;
}
.listHeader .iconContainer {
  width: 38px;
  height: 38px;
}
.listHeader .iconContainer svg {
  width: 22px;
  height: 22px;
}
