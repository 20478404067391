.extraitHeader:hover {
  background-color: rgb(243, 243, 243);
}
.extraitBody {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.extraitBody.show {
  max-height: 3000px;
}
.extraitHeader .bi-caret-down-fill::before {
  transition: all 0.3s ease-in-out;
}
.extraitHeader .bi-caret-down-fill.show::before {
  transform: rotate(-180deg);
}
