.drawerContainer {
  width: 93px;
  overflow: hidden;
  top: 85px;
  height: calc(100vh - 86px);
  box-shadow: 0px 3px 25px #00000014;
  position: fixed;
  z-index: 98;
}
.drawerContainer .iconContainer {
  width: 25px;
  height: 25px;
}
.drawLink {
  width: 48px;
  height: 44px;
  margin-left: 1.3rem;
}
.drawerContainer .linkContent {
  transition: padding 0.2s ease-in-out;
}
.linkName {
  white-space: nowrap;
}
.logoNavigations {
  height: fit-content;
  height: 70%;
}
.drawLink {
  color: #2c2c2c;
  gap: 30px;
  transform-origin: left;
}
.inpage {
  background-color: #f3cd4b;
}
.logout {
  color: white;
  gap: 30px;
  cursor: pointer;
  position: relative;
  flex: 1;
}

/************************************ on hover ********************************/
.drawerContainer:hover {
  width: 305px;
}
.drawerContainer:hover .drawLink {
  width: 274px;
  margin-left: 0.9rem !important;
}
.drawerContainer:hover .linkName {
  margin-left: -10px !important;
  display: block;
}
.drawerContainer:hover .linkContent {
  padding-left: 1rem;
}

.drawLink:hover {
  color: white;
  background-color: #f3cd4b;
}
.drawLink:hover svg {
  fill: white;
}

/**************************************** responsive **********************************/
@media screen and (max-width: 992px) {
  .drawerContainer {
    width: 0px;
    top: 0;
    z-index: 99;
  }
  .drawerContainer:hover {
    width: 0px;
  }
  .drawerContainer.active {
    width: 305px;
    height: 100vh;
  }
  .drawerContainer.active .drawLink {
    width: 274px;
    margin-left: 0.9rem !important;
  }
  .drawerContainer.active .linkName {
    margin-left: -10px !important;
    display: block;
  }
  .drawerContainer.active .linkContent {
    padding-left: 1rem;
  }

  .drawLink.active {
    color: white;
    background-color: #f3cd4b;
  }
  .drawLink.active svg {
    fill: white;
  }
  .drawerContainer .logout {
    margin-bottom: 0px !important;
    border-top: 1px #f2f2f2 solid;
  }
}
