.propertyVotesSection .iconContainer {
  width: 48px;
  height: 48px;
  transition: all 0.5 ease-in-out !important;
}
.propertyVotesSection .iconContainer.vote:hover {
  background-color: white !important;
}
.propertyVotesSection .iconContainer.vote:hover svg path {
  fill: black !important;
}
.propertyVotesSection .iconContainer.view:hover {
  background-color: #2c659d !important;
}
.propertyVotesSection .iconContainer.view:hover svg path {
  fill: white !important;
}
.propertyVotesSection .resolutionsDropdown {
  width: 250px;
  height: 50px;
}
.propertyVotesSection .resolutionsDropdown .p-dropdown-trigger-icon {
  font-size: 20px;
  width: 30px;
  -webkit-text-stroke: 0px;
}
