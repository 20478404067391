.accordionSection .documentsContainer > * {
  margin: 1.5% 0% !important ;
}
.accordionSection .p-accordion-header-link .bi {
  color: #2c659d;
}
.accordionSection .p-accordion .p-accordion-header-link {
  border-radius: 6px !important;
}
.accordionSection .p-accordion .p-accordion-content {
  margin-top: -5px;
}

/* accordion header design on hover */
.accordionSection .p-accordion-header-link:hover p,
.accordionSection .p-accordion-tab-active .p-accordion-header p {
  color: white !important;
}
.accordionSection .p-accordion-header-link:hover .price,
.accordionSection .p-accordion-tab-active .p-accordion-header .price {
  color: #f3cd4b !important;
}

.accordionSection .p-accordion-tab-active .p-accordion-header button.blue {
  color: #2c659d;
  background-color: white;
  border: 2px white solid;
}
.accordionSection
  .p-accordion-tab-active
  .p-accordion-header
  button.blueOutlined {
  color: white;
  background-color: #2c659d;
  border: 2px white solid;
}
.accordionSection .p-accordion-header-link:hover button.blue {
  border-color: white;
}
.accordionSection .p-accordion-header-link:hover button.blueOutlined {
  border-color: white;
}
/******************************* responsive ***************************/
@media screen and (max-width: 992px) {
  .accordionSection .p-accordion .p-accordion-header-link i.bi {
    align-self: start;
  }
}
