.propertyCard {
  background: transparent linear-gradient(117deg, #2c659d 0%, #6993bc 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 2px 15px #00000014;
}
.propertyCard .currencySign {
  right: -0.01rem;
  top: 0.2rem;
}
.propertyCard .extraitBtn:hover {
  background-color: white !important;
  border-color: white !important;
}
.propertyCard .payerBtn:hover {
  background-color: transparent !important;
}
.propertyCardPolygone {
  top: -15%;
}
@media screen and (max-width: 992px) {
  .propertyCard {
    background: #2c659d !important;
  }
  .propertyCardPolygone {
    top: 10%;
    left: 30%;
  }
}
