.w5 {
  width: 5%;
}
.w10 {
  width: 10%;
}
.w12-5 {
  width: 12.5%;
}
.w15 {
  width: 15%;
}
.w20 {
  width: 20%;
}
.w23 {
  width: 23%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w33 {
  width: 33%;
}
.w35 {
  width: 35%;
}
.w40 {
  width: 40%;
}
.w42 {
  width: 42%;
}
.w43 {
  width: 43%;
}
.w44 {
  width: 44%;
}
.w45 {
  width: 45%;
}
.w47 {
  width: 47%;
}
.w48 {
  width: 48%;
}
.w49 {
  width: 49%;
}
.w55 {
  width: 55%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w72 {
  width: 72%;
}
.w73 {
  width: 73%;
}
.w75 {
  width: 75%;
}
.w77 {
  width: 77%;
}
.w78 {
  width: 78%;
}
.w79 {
  width: 79%;
}
.w80 {
  width: 80%;
}
.w85 {
  width: 85%;
}
.w90 {
  width: 90%;
}
.w95 {
  width: 95%;
}
.w100 {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .wm10 {
    width: 10%;
  }
  .wm20 {
    width: 20%;
  }
  .wm30 {
    width: 30%;
  }
  .wm40 {
    width: 40%;
  }
  .wm45 {
    width: 45%;
  }
  .wm50 {
    width: 50%;
  }
  .wm60 {
    width: 60%;
  }
  .wm70 {
    width: 70%;
  }
  .wm75 {
    width: 75%;
  }
  .wm80 {
    width: 80%;
  }
  .wm90 {
    width: 90%;
  }
  .wm100 {
    width: 100%;
  }
  .wm-auto {
    width: auto;
  }
}
