@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}
a {
  text-decoration: none !important;
}
svg {
  transition: all 0.2s ease-in-out;
}
.transition02 {
  transition: all 0.2s ease-in-out;
}
.pointer {
  cursor: pointer;
}
.hide {
  display: none;
}
.ws-nowrap {
  white-space: nowrap;
}

.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus,
.p-accordion-header-link {
  box-shadow: none !important;
}
.p-placeholder,
.p-dropdown-label,
.p-inputtext {
  font-family: "poppins" !important;
  font-weight: 500 !important;
}

/* responsive */

@media only screen and (min-width: 2000px) {
  body {
    max-width: 2250px;
    margin: auto !important;
  }
}
/* box shadow */
.bShadow {
  box-shadow: 0px 2px 15px #00000014;
}

@import url("./CommonStyles/flex.css");
@import url("./CommonStyles/fontSize.css");
@import url("./CommonStyles/fontweight.css");
@import url("./CommonStyles/width.css");
@import url("./CommonStyles/height.css");
@import url("./CommonStyles/stylesFixes.css");
@import url("./CommonStyles/borderRadius.css");
@import url("./CommonStyles/zIndex.css");
@import url("./CommonStyles/border.css");
@import url("./CommonStyles/table.css");
@import url("./CommonStyles/spacing.css");
