.signalementsView .sideBar .signalerButton {
  width: 100%;
  height: 80px;
  display: flex !important;
  justify-content: space-between !important;
  font-size: 25px !important;
}
.signalementsView .sideBar .signalerButton i {
  font-size: 40px;
}
