.moreDetailsBtn {
  width: 25px;
  height: 25px;
}
.moreDetailsBtn > .linksPopup {
  top: 25px;
  right: -37px;
}
.moreDetailsBtn:hover circle {
  fill: #2c659d;
}
