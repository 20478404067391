.b-1-blue {
  border: 1px solid #2c659d;
}
.b-1-lightGrey {
  border: 1px solid #f2f2f2 !important;
}
.b-1-opal {
  border: 1px solid #a9c8bb !important;
}
.b-1-yellow {
  border: 1px solid #f3cd4b !important;
}
.bright-1-lightGrey {
  border-right: 1px solid #f2f2f2 !important;
}
.b-bot-1-lightGrey {
  border-bottom: 1px #dbd8d8 solid;
}
.border-none {
  border: none;
}
