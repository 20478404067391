.m-0 {
  margin: 0;
}
.m-1 {
  margin: 1rem;
}
.unset {
  all: unset;
  cursor: pointer;
}
