.assistanceModal {
  position: fixed;
  z-index: 99;
  right: 3%;
  bottom: 5%;
  width: fit-content;
}
.assistanceModal .formHeader {
  height: 74px;
  border-radius: 5px 5px 0px 0px;
}
.assistanceModal .formHeader .closeIcon {
  position: absolute;
  right: 5%;
  width: 20px;
  height: 20px;
  -webkit-text-stroke-width: 1px;
}
.assistanceModal .formHeader .closeIcon:hover {
  border-radius: 50%;
  color: white;
  background-color: #2c659d;
}
.assistanceModal .formBody {
  box-shadow: 0px 3px 15px #0000001a;
}
.assistanceModal .formBody .p-dropdown,
.assistanceModal .formBody > .p-inputtext {
  border: 1px solid #f2f2f2 !important;
  width: 289px;
}
.assistanceModal .formBody .p-dropdown .pi {
  font-size: 11px;
}
.assistanceModal .formBody .p-dropdown .p-dropdown-label,
.assistanceModal .formBody .p-inputtext::placeholder {
  font-size: 13px;
  color: #333333;
}
.assistanceDropdown .p-dropdown-items .p-dropdown-item {
  font-size: 13px;
  color: #333333;
}
.assistanceModal .formBody > * {
  margin: 2% 0%;
  font-family: "poppins" !important;
}
.assistanceModal .formFooter .customButton {
  margin-top: -5px;
  font-size: 14px;
  height: 56px;
  border: 2px #2c659d solid;
}

.assistanceModal .assistanceForm,
.assistanceModal .assistanceButton {
  animation: pop 0.3s ease-in-out;
}

@media screen and (min-width: 2250px) {
  .assistanceModal {
    right: calc((100vw - 2250px) / 2 + 3%);
  }
}

@keyframes pop {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
