.signalerDialog {
  width: 40vw;
  height: 65vh;
  z-index: 100;
  min-height: 570px;
}
.signalerDialog .p-dialog-header {
  padding: 1rem 1.5rem;
}
.signalerDialog .signalementForm .formTitle {
  margin-top: 0px !important;
}
.signalerDialog .p-dialog-content {
  display: flex;
  flex-direction: column;
  padding: 1% 10%;
}
.signalerDialog .formFooter {
  justify-content: flex-end !important;
}
