.h100 {
  height: 100%;
}
.h85 {
  height: 85%;
}
.h80 {
  height: 80%;
}
@media screen and (max-width: 992px) {
  .hm-auto {
    height: auto;
  }
}
