.budgetSection .accordionHeader {
  min-height: 104px;
}
.budgetSection .p-accordion .p-accordion-header-link {
  border-radius: 6px !important;
}
.budgetSection .p-accordion .p-accordion-content {
  padding: 0px !important;
  border: none;
}
.budgetSection .p-accordion .p-accordion-content .tableHeader {
  border-top: 0px !important;
  border-radius: 0px;
  margin-top: -5px;
  padding-top: calc(1.5rem + 5px) !important;
}

.budgetSection .p-accordion-header-link:hover .customProgressBar .fill,
.budgetSection .p-accordion-tab-active .customProgressBar .fill {
  background: transparent linear-gradient(269deg, #f3cd4b 0%, #f6e6ae 100%) 0%
    0% no-repeat padding-box;
}
