.notificationWrapper {
  position: relative;
  width: 280px;
  box-shadow: 0px 3px 15px #0000000d;
  z-index: 9999;
  background-color: white;
  animation: pop 0.3s ease-in-out;
}
.notificationWrapper .topArrow {
  top: -17px;
  left: 50px;
  color: white;
  position: absolute;
}
.notificationWrapper .bottomArrow {
  transform: rotate(180deg);
  bottom: -17px;
  right: 77px;
  color: white;
  position: absolute;
}

@keyframes pop {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
