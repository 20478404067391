.headerContainer {
  height: 86px;
  position: sticky;
  top: -1px;
  z-index: 99;
  width: 100% !important;
}

@media screen and (max-width: 992px) {
  .headerContainer {
    height: 81px;
    z-index: 98;
  }
}
