$theme-colors: (
  "blue": #2c659d,
  "yankeesBlue": #102a44,
  "yaleBlue": #184f84,
  "lazuliBlue": #2d6aa5,
  "shadowBlue": #7a86a1,
  "lightBlue": #dae8f6,
  "grey": #f2f2f2,
  "lightGrey": #fafafa,
  "levenderGrey": #e4eaf8,
  "mediumGrey": #999999,
  "darkGrey": #333333,
  "dark": #2c2c2c,
  "white": #ffff,
  "lightGray": #f8f8f8,
  "spanishGray": #959292,
  "lightSilver": #dbd8d8,
  "yellow": #f3cd4b,
  "red": rgb(227, 33, 33),
  "opal": #a9c8bb,
  "lightGreen": #d1f5f0,
);

@import "~bootstrap/scss/bootstrap.scss";
